<template>
  <v-card class="mx-auto elevation-0" tile>
    <v-sheet class="primary pa-4">
      <v-text-field
        v-model="search"
        label="Buscar un canal o sucursal"
        dark
        flat
        :dense="searchDense"
        solo-inverted
        hide-details
        clearable
        clear-icon="mdi-close-circle-outline"
      ></v-text-field>
      <v-checkbox
        v-model="caseSensitive"
        dark
        hide-details
        :dense="caseSensitiveDense"
        label="discrimina mayús./minús"
        class="caption"
      ></v-checkbox>
      <template v-if="order">
        <v-divider class="my-2"></v-divider>
        <v-btn color="white" x-small text @click="handlerCaseOrder()">
          <v-icon left>mdi-sort-alphabetical-ascending</v-icon>
          Orden {{ caseOrder ? "ascendente" : "descendente" }}
        </v-btn>
      </template>
    </v-sheet>
    <v-card-text :class="{ 'pa-0 menu-box': true }">
      <v-row
        class="justify-space-between align-center grey lighten-4"
        no-gutters
        v-if="subheaders"
      >
        <v-col>
          <p class="pa-2 mb-0 font-weight-medium">Canales</p>
        </v-col>
        <v-col>
          <p class="text-right mb-0 px-2 font-weight-medium">Responsable</p>
        </v-col>
      </v-row>
      <v-fade-transition>
        <v-overlay v-if="loading" absolute>
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-treeview
          v-else
          :active="Array.isArray(selected) ? selected : [selected]"
          :items="GetChannels"
          item-children="canales"
          item-text="canal_descripcion"
          :search="search"
          :selection-type="selectionType"
          item-key="_id"
          open-all
          :filter="filter"
          hoverable
          :shaped="shaped"
          :dense="dense"
          :selectable="selectable"
          :multiple-active="multiple"
          :value="selectable ? selected : []"
          :disabled="disabled"
          @input="handlerSelectChannel"
        >
          <template v-slot:label="{ item, active }">
            <div :class="{ 'd-flex align-center': actions || editMode }">
              <div
                @click="
                  active
                    ? selectable
                      ? handlerSelectChannel(item?._id ?? '')
                      : $event.stopPropagation()
                    : handlerSelectChannel(item?._id ?? '')
                "
                class="selectable"
              >
                {{
                  cutText(
                    item?.canal_descripcion || "",
                    item?.nivel || 1,
                    item.canales?.length > 0 || false
                  )
                }}
              </div>
              <v-spacer></v-spacer>
              <div class="align-self-center" v-if="actions">
                <v-checkbox
                  dense
                  hide-details
                  :disabled="handlerActionDisabled(item?._id ?? '')"
                  @click="$emit('click-action', item?._id ?? '')"
                  class="ma-0"
                  :input-value="checkActive(item?._id ?? '')"
                >
                </v-checkbox>
              </div>
              <v-btn
                color="primary"
                text
                fab
                x-small
                @click="$emit('edit-channel', item)"
                v-if="editMode"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </template>
        </v-treeview>
      </v-fade-transition>
    </v-card-text>
    <v-card-text
      :class="{ 'elevation-2': $vuetify.breakpoint.smAndDown }"
      v-if="createMode"
    >
      <div class="text-center">
        <v-icon
          large
          color="info"
          class="selectable"
          @click="$emit('add-channel')"
          >mdi-plus-circle-outline</v-icon
        >
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "ChannelIndex",
  props: {
    loading: {
      type: Boolean,
      default: false,
      description: "Estado de carga del menu de canales",
    },
    selected: {
      type: [String, Array],
      default: "",
    },
    actionSelected: {
      type: Array,
      default: () => [],
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    selectionType: {
      type: String,
      default: "independent",
    },
    shaped: {
      type: Boolean,
      default: true,
    },
    createMode: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Boolean,
      default: false,
    },
    subheaders: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
      description: "Muestra boton para editar un canal",
    },
    baseTextLength: {
      type: Number,
      default: 22,
      description: "Numero de caracteres base para el texto de los canales",
    },
    order: {
      type: Boolean,
      default: true,
    },
    searchDense: {
      type: Boolean,
      default: true,
    },
    caseSensitiveDense: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    search: "",
    caseSensitive: false,
    caseOrder: true,
    active: [],
    settings: [],
    channelList: [],
  }),
  computed: {
    ...mapGetters("Channels", ["GetChannels"]),

    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
  },
  methods: {
    ...mapMutations("Channels", ["SetChannelOrder"]),

    cutText(text = "", nivel = 1, hasDependencies = false) {
      if (!(typeof text == "string")) {
        let text = text.toString();
      }
      let stringValue =
        this.baseTextLength -
        (hasDependencies ? nivel + 1 : nivel) -
        (nivel - 1);
      if (stringValue <= 0) {
        stringValue = 1;
      }
      const cuttedText =
        stringValue >= text.length
          ? text
          : `${text.substring(0, stringValue)}...`;
      return cuttedText;
    },
    handlerSelectChannel(e) {
      if (e) {
        this.$emit("select-channel", e);
      }
    },
    handlerActionDisabled(itemId) {
      return this.selected.find((e) => e === itemId) ? false : true;
    },
    checkActive(itemId) {
      return this.actionSelected.find((e) => e === itemId) ? true : false;
    },
    handlerCaseOrder() {
      this.caseOrder = !this.caseOrder;
      this.SetChannelOrder(this.caseOrder);
    },
  },
};
</script>
